.contact-form {
    .contact-details {
        font-size: 2.4rem;
        line-height: 3.6rem;
    }
    label {
    
        font-size: 2rem;
        line-height: 3rem;
    }
    input[type='text'],
    input[type='email'], 
    input[type='url'], 
    input[type='tel'], 
    textarea {
        width: 70%;
    }
    input[type='email'],
    input[type='url'] {
        width: 90%; 
    }
    textarea {
        width: 98%;
    }
}