.list-background-none {
    li {
        background-color: transparent;
    }
}

.list-border-none {
    li {
        border: 0;
    }
}

.list-indent-none {
    li {
        padding-left: 0;
    }
}